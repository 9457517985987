// UserMessage.tsx
import React from 'react';
import { ChatShare } from './chat-share';
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { User } from "@phosphor-icons/react";
import { Varela_Round } from 'next/font/google';
const varela = Varela_Round({
  weight: '400',
  subsets: ['latin'],
});

type MessageItem = {
  type: 'text' | 'image';
  text?: string;
  image: string;
};

type UserMessageProps = {
  message: string | MessageItem[] | any
  chatId?: string;
  showShare?: boolean;
};


type TextMessageProps = {
  text: string;
};

const TextMessage: React.FC<TextMessageProps> = ({ text }) => (
  <div className={`${varela.className} text-[1.5rem] normal-case break-words bg-background text-black rounded-lg py-3`}>
    {text}
  </div>
);


type ImageMessageProps = {
  url: string;
};

const ImageMessage: React.FC<ImageMessageProps> = ({ url }) => (
  <img
    src={url}
    alt="Message content"
    className="rounded-lg max-h-[300px]"
  />
);

export const UserMessage: React.FC<UserMessageProps> = ({
  message,
  chatId,
  showShare = false
}) => {
  const enableShare = process.env.ENABLE_SHARE === 'true';

  // Helper functions to render messages
  const renderTextMessages = (messages: MessageItem[]) =>
    messages
      .filter(msg => msg.type === 'text' && msg.text)
      .map((msg, index) => <TextMessage key={index} text={msg.text!} />);

  const renderImageMessages = (messages: MessageItem[]) =>
    messages
      .filter(msg => msg.type === 'image' && msg.image)
      .map((msg, index) => <ImageMessage key={index} url={msg.image} />);

  return (
    <div className="flex flex-col items-start w-full space-x-1 mt-[50px] min-h-10 justify-start mb-2">
      <div className="w-full pt-[1px] my-8 bg-gray-200 border-0 dark:bg-gray-700"></div>
      <div className="flex flex-col space-y-2 max-w-[70%]">
        {Array.isArray(message) ? (
          <>
            {/* Render Text Messages */}
            {renderTextMessages(message)}
            
            {/* Render Image Messages */}
            {renderImageMessages(message)}
          </>
        ) : (
          <TextMessage text={message} />
        )}
      </div>
    </div>
  );
};
