'use client'

import { useEffect, useState, useRef } from 'react'
import { usePathname } from 'next/navigation'
import { ChatPanel } from './chat-panel'
import { ChatMessages } from './chat-messages'
import { useUIState } from 'ai/rsc'
import { useAppState } from "@/lib/utils/app-state";
import Minimap from "minimap-react";
import "minimap-react/lib/react-minimap.css";


type ChatProps = {
  id?: string
  query?: string
}

export function Chat({ id, query }: ChatProps) {
  const path = usePathname()
  const [messages] = useUIState()
  const [lastScrollTop, setLastScrollTop] = useState<number>(0);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const {
    isGenerating,
    setIsGenerating,
    autoScrollMode,
    setAutoScrollMode,
    isMessagesEmpty,
    setIsMessagesEmpty,
  } = useAppState();

  useEffect(() => {
    if (!path.includes('search') && messages.length === 1) {
      // window.history.replaceState({}, '', `/search/${id}`)
    }
  }, [id, path, messages, query])

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const scrollTop = scrollContainerRef.current.scrollTop;
      if (scrollTop < lastScrollTop) {
        // Trigger your function when scrolling up
        setAutoScrollMode(false);
      }
      setLastScrollTop(scrollTop);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [lastScrollTop]);

  useEffect(() => {
    let scrollInterval: NodeJS.Timeout | undefined;
    if (isGenerating && autoScrollMode) {
      // Scroll to the bottom while generating
      scrollInterval = setInterval(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    } else {
      // Scroll to the bottom when not generating
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      if (scrollInterval) {
        clearInterval(scrollInterval);
      }
    }
    return () => {
      if (scrollInterval) {
        clearInterval(scrollInterval);
      }
    };
  }, [isGenerating, autoScrollMode]);

  useEffect(() => {
    // Define the breakpoint for mobile devices
    const MOBILE_BREAKPOINT = 768; // You can adjust this value as needed

    // Function to check screen width
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);
    };

    // Initial check
    checkIsMobile();

    // Add event listener for window resize
    window.addEventListener('resize', checkIsMobile);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  return (
    <div className="px-8s sm:px-12 pt-12 md:pt-14 pb-14 md:pb-24 max-w-[1120px] mx-auto flex flex-col space-y-3 md:space-y-4">
      {/* Messages Scrollable Area */}
      <div ref={scrollContainerRef} className="flex flex-col h-full overflow-y-auto p-4">
        {/* Chat Messages */}
        <div id="ChatMessages" className="flex-1 grid gap-4">
          <ChatMessages messages={messages} />
        </div>
        
        {/* Minimap for Chat */}
        {!isMobile && messages.length > 0 && (
          <Minimap
            rootId="ChatMessages"
            position="right"
            smoothScroll={true}
            smoothScrollDelay={200}
            keepAspectRatio={true}
            toggle={true}
            className=""
            style={{
              zIndex: 10, // Set the highest z-index
            }}
          />
        )}
        
        {/* Message End Reference for Auto Scroll */}
        <div ref={messagesEndRef} />
      </div>

      {/* Chat Panel */}
      <ChatPanel />
    </div>
  )
}
