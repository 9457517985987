"use client";

import { Button } from "@/components/ui/button";
import { useEdgeStore } from '@/lib/edgestore';
import { useAppState } from "@/lib/utils/app-state";
import fetcher from "@/utils/fetcher";
import { Paperclip } from "@phosphor-icons/react";
import { generateId } from "ai";
import { useActions, useUIState } from "ai/rsc";
import { useSession } from "next-auth/react";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import useSWR from 'swr';
import { BotMessage } from "./message";
import { Modal } from "./Modal";
import { Pricing } from "./pricing";
import { UserMessage } from "./user-message";
import { Toaster, toast } from 'sonner';
import Textarea from 'react-textarea-autosize';
import { Varela_Round } from 'next/font/google';
import { Label } from "@/components/ui/label";
import { usePathname } from 'next/navigation';
import { v4 as uuidv4 } from 'uuid';
import { CornerDownLeft } from "lucide-react";
import { useRouter } from "next/navigation";

const varela = Varela_Round({
  weight: '400',
  subsets: ['latin'],
});

export function ChatPanel() {
  const pathname = usePathname();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [input, setInput] = useState("");
  const [imgFile, setImgFile] = useState<File | null>(null);
  const [imgPreview, setImgPreview] = useState("");
  const [reachedDailyLimit, setReachedDailyLimit] = useState<boolean>(false);
  const [showPricing, setShowPricing] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { edgestore } = useEdgeStore();
  const { submit } = useActions();
  const [messages, setMessages] = useUIState(); // Assuming useUIState provides messages
  const [isMobile, setIsMobile] = useState(false);
  const router = useRouter();
  const {
    isGenerating,
    setIsGenerating,
    autoScrollMode,
    setAutoScrollMode,
    // Remove isMessagesEmpty from useAppState
    isChatActivated,
    setIsChatActivated
  } = useAppState();

  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isTransformingSpeechToText, setIsTransformingSpeechToText] = useState<boolean>(false);

  const { data: userSessionData, status: userStatus } = useSession();

  const { data: dailyUsageData } = useSWR(
    userSessionData?.user.id ? `/api/usage/daily?userId=${userSessionData.user.id}` : null,
    fetcher
  );

  const { data: userData } = useSWR(
    userSessionData?.user.id ? `/api/user?id=${userSessionData.user.id}` : null,
    fetcher
  );

  useEffect(() => {
    const MOBILE_BREAKPOINT = 768;

    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);
    };

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  useEffect(() => {
    if (!userData) {
      return;
    }

    if (userData?.isActive) {
      setReachedDailyLimit(false);
      return;
    }

    if (dailyUsageData?.data?.chatStatus === false) {
      setReachedDailyLimit(true);
    }
  }, [userData, dailyUsageData]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "4rem";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      if (textareaRef.current.scrollHeight > 192) {
        textareaRef.current.style.height = "12rem";
      }
    }
  }, [input]);

  useEffect(() => {
    // console.log('Pathname changed:', pathname);
    // console.log('Is Messages Empty:', messages.length === 0);
  }, [pathname, messages.length]);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setImgFile(files[0]);
      setImgPreview(URL.createObjectURL(files[0]));
    }
  };

  const handleRemove = () => {
    setImgFile(null);
    setImgPreview("");
  };

  const secretKey = 'mySecretKey'; // Replace with a secure key

  function encrypt(text: any, key: any) {
    const textBytes = new TextEncoder().encode(text);
    const keyBytes = new TextEncoder().encode(key);

    const encryptedBytes = textBytes.map(
      (byte, i) => byte ^ keyBytes[i % keyBytes.length]
    );

    return btoa(String.fromCharCode(...encryptedBytes as any));
  }

  function decrypt(encryptedText: any, key: any) {
    const encryptedBytes = Uint8Array.from(
      atob(encryptedText),
      (char) => char.charCodeAt(0)
    );
    const keyBytes = new TextEncoder().encode(key);

    const decryptedBytes = encryptedBytes.map(
      (byte, i) => byte ^ keyBytes[i % keyBytes.length]
    );

    return new TextDecoder().decode(decryptedBytes);
  }

  const checkUsageLimit = () => {
    try {
      const today = new Date().toISOString().slice(0, 10);
      const encryptedStoredDate = localStorage.getItem('f6de');
      const storedDate = encryptedStoredDate
        ? decrypt(encryptedStoredDate, secretKey)
        : null;

      const encryptedQueryCount = localStorage.getItem('6018fcceff64');
      let queryCount = encryptedQueryCount
        ? parseInt(decrypt(encryptedQueryCount, secretKey))
        : 0;

      if (storedDate !== today) {
        queryCount = 0;
        const encryptedToday = encrypt(today, secretKey);
        localStorage.setItem('f6de', encryptedToday);
      }

      if (queryCount >= 5) {
        setReachedDailyLimit(true);
        toast.error('Daily limit reached. Please login to continue.');
      } else {
        queryCount += 1;
        const encryptedQueryCount = encrypt(queryCount.toString(), secretKey);
        localStorage.setItem('6018fcceff64', encryptedQueryCount);
        setReachedDailyLimit(false);
      }
    } catch (e) {
      console.error('Error in checkUsageLimit:', e);
    }
  };

  useEffect(() => {
    if (userStatus !== 'loading' && userStatus === 'unauthenticated') {
      checkUsageLimit();
    }
  }, [userStatus]);

  const handleSubmit = async (input_?: string) => {
    setIsChatActivated(true);
    if ((input_ || input).trim().length < 1 && !imgFile) {
      toast.error("Enter some text or attach an image to use this feature");
      return;
    }

    if (userStatus !== 'loading' && userStatus === 'unauthenticated') {
      checkUsageLimit();
      if (reachedDailyLimit) {
        return;
      }
    }

    if (reachedDailyLimit) {
      return;
    }

    setIsGenerating(true);
    setAutoScrollMode(true);

    type MessageContentType =
      | string
      | { type: "text"; text: string }
      | { type: "text"; text: string }[]
      | ({ type: "text"; text: string } | { type: "image"; image: string })[];

    let messageContent: MessageContentType;
    messageContent = input_ || input;

    if (imgFile) {
      try {
        const res = await edgestore.publicFiles.upload({
          file: imgFile
        });

        if (res.url) {
          messageContent = [
            { type: "text", text: input },
            {
              type: "image",
              image: res.url,
            },
          ];
        }
      } catch (error) {
        messageContent = [
          { type: "text", text: input },
          {
            type: "image",
            image: '',  // Handle the fallback here
          },
        ];
      }
    }

    // Add user message to UI state
    setMessages((currentMessages: any) => [
      ...currentMessages,
      {
        id: generateId(),
        component: <UserMessage message={messageContent} />,
      },
    ]);

    const data = {
      userInput: {
        role: "user",
        content: messageContent,
      },
    };

    setInput("");
    textareaRef.current?.focus();

    const responseMessage = await submit(data);

    if (process.env.NEXT_PUBLIC_WEBSITE_URL) {
      await fetch(`${process.env.NEXT_PUBLIC_WEBSITE_URL}/api/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: messageContent }),
      });
    }

    setMessages((currentMessages: any) => [...currentMessages, responseMessage]);
    setImgFile(null);
    setImgPreview("");
    setIsGenerating(false);
  };

  const onTranscriptionComplete = (text: string) => {
    setInput(text);
    setIsTransformingSpeechToText(false);
  };

  const questions = [
    "product (1-1/n^4), n=2 to infinity",
    "Explain theory of natural rights",
    "Explain the laws of thermodynamics",
    "Show me videos and images famous Revolutions in History",
    // "summarize this : https://math.berkeley.edu/~hutching/teach/proofs.pdf",
  ];

  // Derive isMessagesEmpty from messages length
  const isMessagesEmpty = messages.length === 0;

  return (
    <div>
      {(pathname === '/' || pathname === '/home') && isMessagesEmpty ? (
        <div
          className={
            `fixed ${!isMobile ? 'bottom-8' : 'bottom-[-60px]'} left-0 right-0 top-100 mx-auto h-screen ml-2 mr-2 flex flex-col items-center justify-center`
          }
        >
          <Toaster />
          <div className="flex flex-col items-center justify-center mb-8 m-2">
            <span className={`${varela.className} text-[4.45rem] text-center`}>Fetchit</span>
            <span className="text-[2.05rem] font-semibold text-center">
              Feed <span className={`bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent`}>Your</span> Curiosity...
            </span>
          </div>
          {imgFile && (
            <div className="flex flex-row mls-10 w-[870px] p-1 relative">
              <Image
                className="border-[2px] border-blue-200 bg-gray-100 rounded-md"
                src={imgPreview}
                alt="File Thumbnail"
                width={150}
                height={150}
                objectFit="contain"
              />
              <button
                onClick={handleRemove}
                className="absolute bg-red-500 text-white border-none rounded-full w-5 h-5 cursor-pointer text-center leading-5 text-sm"
                style={{ transform: "translate(-50%, -50%)" }}
              >
                X
              </button>
            </div>
          )}
          <div className="relative flex flex-col w-full max-w-[870px] ml-2 mr-2 rounded-xl bg-gradient-to-r from-blue-500 to-purple-600 p-[3px] lg:col-span-2">
            <div
              className="relative overflow-hidden rounded-lg border bg-background shadow-none focus:outline-none focus-visible:ring-0 focus-visible:border-none"
            >
              <Label htmlFor="message" className="sr-only">
                Message
              </Label>
              <Textarea
                id="message"
                placeholder="Ask anything..."
                className="min-h-12 max-h-[300px] w-full resize-none border-0 p-2 md:p-4 shadow-none focus:outline-none focus-visible:ring-0 focus-visible:border-none"
                value={input}
                disabled={reachedDailyLimit}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => {
                  if (!isRecording || !isTransformingSpeechToText || input.length > 1) {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleSubmit();
                    }
                  }
                }}
              />

              <div className="flex items-center p-3 pt-0">
                <Button className={`${reachedDailyLimit && 'hidden'}`} variant="ghost" size="icon" onClick={handleButtonClick}>
                  <Paperclip className="size-4" />
                  <span className="sr-only">Attach file</span>
                </Button>

                {userStatus !== 'loading' && userStatus === 'unauthenticated' && reachedDailyLimit &&
                  <div className="flex items-center justify-center w-full">
                    <a href="/auth">
                      <Button
                        variant="outline"
                        className="w-full mt-2 text-black"
                      >
                        Continue with <img
                          src="https://cdn-teams-slug.flaticon.com/google.jpg"
                          width={25}
                          height={25}
                        />Google or Email
                      </Button>
                    </a>
                  </div>
                }

                {userStatus !== 'loading' && userStatus === 'authenticated' && reachedDailyLimit &&
                  <div className="flex items-center justify-center w-full">
                    <span className={`${varela.className} text-[1rem] text-center mt-2 bg-clip-text text-reds-600`}>
                      We've used up all of free daily usage. Upgrade to pro to continue searching...
                    </span>
                  </div>
                }

                <Button
                  type="submit"
                  size="sm"
                  disabled={reachedDailyLimit}
                  className={`ml-auto gap-1.5 bg-black rounded ${reachedDailyLimit && 'hidden'}`}
                  onClick={() => handleSubmit()}
                >
                  Ask Away
                  <CornerDownLeft className="size-3.5" />
                </Button>
              </div>
              {userStatus !== 'loading' && userStatus === 'authenticated' && reachedDailyLimit &&
                <div className="flex items-center justify-center w-full">
                  <span className={`${varela.className} text-black text-[2rem]`}>$5.99</span><span className={`${varela.className} text-lg font-normal`}>/month. Enough for a coffee <span className="text-[2rem] align-middle">☕</span></span>
                  <Button
                    type="submit"
                    size="sm"
                    disabled={!reachedDailyLimit}
                    className="ml-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-md"
                    onClick={() => setShowPricing(true)}
                  >
                    Go pro
                  </Button>
                </div>
              }
            </div>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleFileChange}
              disabled={reachedDailyLimit}
              style={{ display: 'none' }}
            />
          </div>

          <div
            className={`max-w-full ${isMobile ? 'max-h-[50vh]' : 'max-h-[80vh]'} mt-10 mx-auto overflow-y-auto flex flex-col justify-center items-center`}
          >
            <div className="flex flex-wrap items-center justify-center gap-2">
              {questions.map((question, index) => (
                <div key={index} className="w-full sm:w-auto">
                  <Button
                    type="button"
                    variant="outline"
                    className="items-center mt-2 px-4 py-2 border-gray-300 text-gray-100 rounded-md bg-[#1692ff] hover:bg-gray-100 whitespace-normal break-words"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit(question);
                    }}
                  >
                    <BotMessage content={question} />
                  </Button>
                </div>
              ))}
            </div>
          </div>

          <Modal
            isOpen={showPricing}
            onOpenChange={setShowPricing}
            width={900}
          >
            <Pricing />
          </Modal>
        </div>
      ) : (
        <div
          className={
            'fixed bottom-2 left-0 right-0 top-100 mx-auto ml-2 mr-2 flex flex-col items-center justify-center'
          }
        >
          {imgFile && (
            <div className="flex flex-row mls-10 w-[870px] p-1 relative">
              <Image
                className="border-[2px] border-blue-200 bg-gray-100 rounded-md"
                src={imgPreview}
                alt="File Thumbnail"
                width={150}
                height={150}
                objectFit="contain"
              />
              <button
                onClick={handleRemove}
                className="absolute bg-red-500 text-white border-none rounded-full w-5 h-5 cursor-pointer text-center leading-5 text-sm"
                style={{ transform: "translate(-50%, -50%)" }}
              >
                X
              </button>
            </div>
          )}
          <div className="relative flex flex-col w-full max-w-[870px] ml-2 mr-2 rounded-xl bg-gradient-to-r from-blue-500 to-purple-600 p-[3px] lg:col-span-2">
            <div
              className="relative overflow-hidden rounded-lg border bg-background shadow-none focus:outline-none focus-visible:ring-0 focus-visible:border-none"
            >
              <div className="flex items-center p-3s pl-3 pr-3 pt-0">
                <Button variant="ghost" size="icon" onClick={handleButtonClick}>
                  <Paperclip className="size-4" />
                  <span className="sr-only">Attach file</span>
                </Button>
                <Label htmlFor="message" className="sr-only">
                  Message
                </Label>
                <Textarea
                  id="message"
                  placeholder="Ask a follow up question"
                  className="min-h-12 max-h-[300px] w-full resize-none border-0 p-2 md:p-4 shadow-none focus:outline-none focus-visible:ring-0 focus-visible:border-none"
                  value={input}
                  disabled={reachedDailyLimit}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (!isRecording || !isTransformingSpeechToText || input.length > 1) {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        handleSubmit();
                      }
                    }
                  }}
                />
                <Button
                  type="submit"
                  size="sm"
                  className="ml-auto gap-1.5 bg-black rounded"
                  onClick={() => handleSubmit()}
                >
                  Ask
                  <CornerDownLeft className="size-3.5" />
                </Button>
              </div>
            </div>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleFileChange}
              disabled={reachedDailyLimit}
              style={{ display: 'none' }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
